<template>
  <v-dialog
    v-model="pdfDialog"
    @click:outside="$emit('update:pdfDialog', false)"
    @keydown.esc="$emit('update:pdfDialog', false)"
    scrollable
    fullscreen
  >
    <v-card tile>
      <v-card-title class="pa-4 font-weight-bold accent">
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:pdfDialog', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0" style="height: calc(100vh - 61px)">
        <iframe
          :src="pdfData"
          frameborder="0"
          width="100%"
          height="100%"
        ></iframe>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PdfDialogComp",

  props: {
    pdfDialog: {
      type: Boolean,
      required: true,
    },
    pdfData: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss"></style>
